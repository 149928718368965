import request from '@/utils/request'
// const baseUrlState = 'https://teststate.renkangshangcheng.cn/api/userservice'
const baseUrlState = 'https://state.renruikeji.cn/api/userservice'
const baseUrlStateData = 'https://state.renruikeji.cn/api/baseData'
const baseUrl = 'https://livechat.renruikeji.cn'
const baseUrlWechat = 'https://wechat.renruikeji.cn/'
const baseUrlApplets = 'https://applets.renruikeji.cn/'
const baseUrlKefu = 'https://kefu.renruikeji.cn/'
// 获取企业微信 js sdk config配置信息
export function getAllConfig(data) {
  return request({
    url: '/group/getQxJsticketSign',
    method: 'post',
    params: data,
    baseURL: baseUrlState
  })
}
// 获取企业微信 js sdk agent配置信息
export function getQxJsSdkConfig(data) {
  return request({
    url: '/login/getAgentConfigById',
    method: 'post',
    params: data,
    baseURL: baseUrlApplets
  })
}
// 获取js sdk 配置信息
export function getJsSdkConfig(data) {
  return request({
    url: '/group/getJsticketSign',
    method: 'post',
    params: data,
    baseURL: baseUrlState
  })
}
// 获取密钥
export function getCosToken(data) {
  return request({
    url: '/material/getBuckTmpSecret',
    method: 'post',
    params: data,
    baseURL: baseUrlKefu
  })
}
// 获取分享信息
export function getLiveInfo(data) {
  return request({
    url: '/livechat/live',
    method: 'post',
    params: data,
    baseURL: baseUrl
  })
}
// 新增直播信息
export function addLive(data) {
  return request({
    url: '/group/jumpLive',
    method: 'post',
    data,
    dataType: 'json',
    baseURL: baseUrlState
  })
}
// 获取直播列表
export function getLiveList(data) {
  return request({
    url: '/group/jumpLiveList',
    method: 'post',
    params: data,
    baseURL: baseUrlState
  })
}
// 判断用户是否可授权
export function liveAuth(data) {
  return request({
    url: '/group/liveAuth',
    method: 'post',
    params: data,
    baseURL: baseUrlState
  })
}
// 用户进入直播间
export function enterLive(data) {
  return request({
    url: '/group/liveIn',
    method: 'post',
    params: data,
    baseURL: baseUrlState
  })
}
// 用户退出直播间
export function outLive(data) {
  return request({
    url: '/group/liveOut',
    method: 'post',
    params: data,
    baseURL: baseUrlState
  })
}
// 获取用户id
export function getUserId(data) {
  return request({
    url: '/packetConfig/getWxAppTokenByCode',
    method: 'get',
    params: data,
    baseURL: baseUrlWechat
  })
}
// 获取企业微信userId
export function getQxUserId(data) {
  return request({
    url: '/group/login',
    method: 'post',
    params: data,
    baseURL: baseUrlState
  })
}
// 获取群聊列表
export function getGroupList(data) {
  return request({
    url: '/group/getLivingChatListByUserId',
    method: 'post',
    params: data,
    baseURL: baseUrlState
  })
}
// 保存配置
export function saveConfig(data) {
  return request({
    url: '/group/setupLivingChatList',
    method: 'post',
    data,
    dataType: 'json',
    baseURL: baseUrlState
  })
}
// 获取分享
export function getHistory(data) {
  return request({
    url: '/tags/live/info',
    method: 'get',
    params: data,
    baseURL: baseUrlState
  })
}
// 获取直播明细
export function getUserList(data) {
  return request({
    url: '/tags/live/getLiveDetail',
    method: 'get',
    params: data,
    baseURL: baseUrlState
  })
}
// 获取红包配置
export function getRedConfig(data) {
  return request({
    url: '/tags/live/getWatchTimeRedSetupList',
    method: 'get',
    params: data,
    baseURL: baseUrlState
  })
}
//保存红包配置
export function saveRedConfig(data) {
  return request({
    url: '/tags/live/updateLivingWatchTimeRedSetup',
    method: 'post',
    data,
    dataType: 'json',
    baseURL: baseUrlState
  })
}
// 获取部门信息
export function getDepById(data) {
  return request({
    url: '/staffManage/getDepById',
    method: 'post',
    params: data,
    baseURL: baseUrlWechat
  })
}
// 获取所有部门信息
export function getAllDep(data) {
  return request({
    url: '/department/getAllDept',
    method: 'post',
    params: data,
    baseURL: baseUrlStateData
  })
}
export function sendNotice(data) {
  return request({
    url: '/tags/live/sendLiveNoticeMsg',
    method: 'post',
    data,
    dataType: 'json',
    baseURL: baseUrlState
  })
}

